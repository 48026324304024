import React, { useState, useEffect } from "react";

import { useAuthContext } from "../../hooks/useAuthContext";
const CreateProject = () => {
  const [projectId, setProjectId] = useState("");
  const [file, setFile] = useState(null);
  const [settingId, setSettingId] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [enterpriseId, setEnterpriseId] = useState("");
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [submissionResult, setSubmissionResult] = useState(null);
  const { user } = useAuthContext();
  useEffect(() => {
    // Fetch the list of enterprises
    const fetchEnterprises = async () => {
      try {
        const response = await fetch("/api/enterprise/list");
        if (response.ok) {
          const result = await response.json();
          setEnterpriseOptions(result.enterprises);
        } else {
          console.error("Error fetching enterprise list:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching enterprise list:", error);
      }
    };

    fetchEnterprises();
  }, []); // Run the effect only once on component mount

  const handleProjectIdChange = (e) => {
    setProjectId(e.target.value);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSettingIdChange = (e) => {
    setSettingId(e.target.value);
  };

  const handlePolicyNameChange = (e) => {
    setPolicyName(e.target.value);
  };

  const handleEnterpriseIdChange = (e) => {
    setEnterpriseId(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      // First API call to '/api/policy'
      const policyFormData = new FormData();
      policyFormData.append("setting_id", settingId);
      policyFormData.append("policyName", policyName);
      policyFormData.append("enterpriseId", enterpriseId); // Use the selected enterpriseId
      policyFormData.append("applicationPolicy", file);

      //policyFormData should look like this if i understood you correctly
      // {
      //     "setting_id" : settingId ,
      //     "policyName":  "policyName",
      //     "enterpriseId": `enterprises/${enterpriseId}`,
      //     "applicationPolicy": file
      // }
      //setting_id , policyName should be typed manually and enterpriseId should come from user dropdown menu input.
      

      await fetch("/api/policy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: policyFormData,
      });

      // Second API call to '/api/saveServiceAccount'
      const serviceAccountFormData = new FormData();
      // this is being sent to  (const jsonName = req.body.name) to saveServiceAccount controller in the backend
      serviceAccountFormData.append("name", projectId);
      // this is being sent to (const jsonName = req.body.data)  to saveServiceAccount controller in the backend
      serviceAccountFormData.append("data", file);

      const response = await fetch("/api/saveServiceAccount", {
        method: "POST",
        body: serviceAccountFormData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setSubmissionResult("Submission successful!");
      } else {
        console.error("Error:", response.statusText);
        setSubmissionResult("Error submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmissionResult("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div>
      <h1>Project Page</h1>
      <form>
        {/* ... (previous input fields) */}
        <div>
          <label>Enterprise ID:</label>
          <select
            required
            value={enterpriseId}
            onChange={handleEnterpriseIdChange}
          >
            <option value="" disabled>
              Select an enterprise
            </option>
            {enterpriseOptions.map((enterprise) => (
              <option key={enterprise.name} value={enterprise.name}>
                {enterprise.enterpriseDisplayName}
              </option>
            ))}
          </select>
        </div>
        {/* ... (previous input fields) */}
      </form>
      <button type="button" onClick={handleSubmit}>
        Submit
      </button>
      {submissionResult && <p>{submissionResult}</p>}
    </div>
  );
};

export default CreateProject;
