import { useState, useEffect } from 'react';

const useFetchUsers = (token) => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch("/api/user/getusers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const userData = await response.json();
        setUsers(userData);
      } catch (error) {
        setError("Error fetching users");
      }
    };

    fetchUsers();
  }, [token]);

  return { users, error };
};

export default useFetchUsers;
