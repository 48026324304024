import React, { useEffect } from "react";
import { useOrganizationsContext } from "../hooks/useOrganizationsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import { format } from 'date-fns';

const Home = () => {
  const { organizations, dispatch } = useOrganizationsContext();
  const { user } = useAuthContext();
  
  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await fetch("/api/organization/", {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const json = await response.json();

      console.log(user);
      console.log("the json data is as follows: ");
      console.log(json);
    
      if (response.ok) {
        dispatch({ type: "SET_ORGANIZATION", payload: json });
      }
    };
    if (user) {
      fetchOrganizations();
    }
  }, [dispatch, user]);

  return (
    <div className="home">
      <div className="org-container" style={{ display: "flex", justifyContent: "center" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid #333" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Organization Name</th>
              <th style={tableHeaderStyle}>Created At</th>
              <th style={tableHeaderStyle}>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.map((organization) => (
                <tr key={organization.id}>
                  <td style={tableCellStyle}>
                    <Link to={`/organization/${organization.id}`}>
                      {organization.name}
                    </Link>
                  </td>
                  <td style={tableCellStyle}>{format(new Date(organization.createdAt), "MMM d, yyyy HH:mm")}</td>
                  <td style={tableCellStyle}>{format(new Date(organization.updatedAt), "MMM d, yyyy HH:mm")}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  borderBottom: "2px solid #333",
  borderRight: "1px solid #333",
  borderLeft: "1px solid #333", // Add vertical line
  padding: "10px",
  textAlign: "left",
};

const tableCellStyle = {
  borderBottom: "1px solid #ddd",
  borderRight: "1px solid #ddd",
  borderLeft: "1px solid #ddd", // Add vertical line
  padding: "10px",
  textAlign: "left",
};

export default Home;