import React, { useState } from 'react';

const EnterpriseForm = ({ onCreate }) => {
  const [formData, setFormData] = useState({
    contactEmail: '',
    dataProtectionOfficerName: '',
    dataProtectionOfficerEmail: '',
    dataProtectionOfficerPhone: '',
    euRepresentativeName: '',
    euRepresentativeEmail: '',
    euRepresentativePhone: '',
    enterpriseDisplayName: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  return (
    <div>
      <h2>Create Enterprise</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Contact Email:</label>
          <input
            type="text"
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>Data Protection Officer Name:</label>
          <input
            type="text"
            name="dataProtectionOfficerName"
            value={formData.dataProtectionOfficerName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>Data Protection Officer Email:</label>
          <input
            type="email"
            name="dataProtectionOfficerEmail"
            value={formData.dataProtectionOfficerEmail}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>Data Protection Officer Phone:</label>
          <input
            type="tel"
            name="dataProtectionOfficerPhone"
            value={formData.dataProtectionOfficerPhone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>EU Representative Name:</label>
          <input
            type="text"
            name="euRepresentativeName"
            value={formData.euRepresentativeName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>EU Representative Email:</label>
          <input
            type="email"
            name="euRepresentativeEmail"
            value={formData.euRepresentativeEmail}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>EU Representative Phone:</label>
          <input
            type="tel"
            name="euRepresentativePhone"
            value={formData.euRepresentativePhone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <label>Enterprise Display Name:</label>
          <input
            type="text"
            name="enterpriseDisplayName"
            value={formData.enterpriseDisplayName}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit">Create</button>
      </form>
    </div>
  );
};

export default EnterpriseForm;
