import React, { useState, useEffect } from 'react';
import EnterpriseList from './EnterpriseList';
import EnterpriseDetails from './EnterpriseDetails';
import EnterpriseForm from './EnterpriseForm';

const EnterprisePage = () => {
  const [enterprises, setEnterprises] = useState([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);

  useEffect(() => {
    // Fetch enterprise list on component mount
    fetch('/api/enterprise/list')
      .then((response) => response.json())
      .then((data) => setEnterprises(data.enterprises))
      .catch((error) => console.error('Error fetching enterprises:', error));
  }, []);

  //this is for (router.delete("/", deleteEnterprise)) in enterprise route
  const handleDelete = (enterpriseName) => {
    // Send delete request and update state
    fetch('/api/enterprise', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ enterpriseId: `enterprises/${enterpriseName}` }),
    })
      .then((response) => {
        if (response.ok) {
          setEnterprises((prevEnterprises) =>
            prevEnterprises.filter((enterprise) => enterprise.name !== enterpriseName)
          );
        } else {
          console.error('Error deleting enterprise:', response.statusText);
        }
      })
      .catch((error) => console.error('Error deleting enterprise:', error));
  };

  const handleNavigate = (enterpriseName) => {
    // Redirect to a new enterprise page with details for the selected enterprise
    setSelectedEnterprise(enterpriseName);
  };

  const handleCreate = (formData) => {
    // Send create request and update state
    fetch('/api/enterprise', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setEnterprises((prevEnterprises) => [...prevEnterprises, data]);
      })
      .catch((error) => console.error('Error creating enterprise:', error));
  };

  if (selectedEnterprise) {
    // Render details page for the selected enterprise , this way you don't need a route to get a single enterprise,
    // but you have to get the data for the all enterprises first from this page,
    // so you cant refresh a single enterprise details page aka "EnterpriseDetails" ,
    //so for now it would be a component living inside the main EnterprisePage
    console.log(selectedEnterprise)
    console.log("********************************")

    return <EnterpriseDetails enterprise={selectedEnterprise} />;
  }

  return (
    <div>
      <EnterpriseList enterprises={enterprises} onDelete={handleDelete} onNavigate={handleNavigate} />
      <EnterpriseForm onCreate={handleCreate} />
    </div>
  );
};

export default EnterprisePage;
