import React, { createContext, useReducer } from "react";

export const SettingsContext = createContext();

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case "SET_SETTING":
      return {
        ...state,
        settings: action.payload,
      };
    case "CREATE_SETTING":
      return {
        ...state,
        settings: [action.payload, ...state.settings],
      };
    case "DELETE_SETTING":
      return {
        ...state,
        settings: state.settings.filter((setting) => setting._id !== action.payload),
      };
    case "EDIT_SETTING":
      return {
        ...state,
        settings: state.settings.map((setting) =>
          setting._id === action.payload.id
            ? { ...setting, name: action.payload.newName }
            : setting
        ),
      };
    default:
      return state;
  }
};


export const SettingsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(settingsReducer, {
    settings: [],
  });

  return (
    <SettingsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SettingsContext.Provider>
  );
};
