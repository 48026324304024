import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useState } from "react";
// pages & components
import CreateOrganization from "./pages/admin/CreateOrganization/CreateOrganization";
import Home from "./pages/home";
import Login from "./pages/Login";
import CreateUser from "./pages/admin/CreateUser/CreateUser";
import Admin from "./pages/admin/Admin";
import { OrganizationsDetails } from "./pages/organization/OrganizationsDetails/OrganizationsDetails";
import { AdminOrgDetails } from "./pages/admin/AdminOrgDetails";
import Navbar from "./components/Navbar";
import CreateProject from "./pages/admin/CreateProject";
import EnterprisePage from "./pages/admin/enterprise/EnterprisePage";
import CreateSettingsForOrg from "./pages/organization/CreateSettings/CreateSettings";
import SettingsList from "./pages/organization/SettingsList/SettingsList";
import CreateAuth from "./pages/admin/CreateAuth";
import AuthGuard from "./AuthGuard"; 



function App() {
  const { user } = useAuthContext();
  const [isThere, setIsThere] = useState(false);

  const checkOAuthFile = async () => {
    try {
      const response = await fetch('/api/serviceAccount/isthere');
      setIsThere(response.status === 200 || response.status === 304);
    } catch (error) {
      console.error('Error:', error);
      setIsThere(false);
    }
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route
              path="/admin"
              element={
                user ? (
                  user.role === "admin" ? (
                    <AuthGuard  Component={Admin } isThere={isThere} checkOAuthFile={checkOAuthFile} />
                  ) : 
                    <Home/>
                  
                ) : (
                  <Login/>
                )
              }
            />
            <Route
              path="/createproject"
              element={
                user ? (
                  user.role === "admin" ? (
                
                    <AuthGuard  Component={CreateProject } isThere={isThere} checkOAuthFile={checkOAuthFile} />
                  ) : 
                    <Home/>
                  
                ) : (
                  <Login/>
                )
              }
            />
            <Route
              path="/EnterprisePage"
              element={
                user ? (
                  user.role === "admin" ? (
                   
                    <AuthGuard  Component={EnterprisePage } isThere={isThere} checkOAuthFile={checkOAuthFile} />
                  ) : 
                    <Home/>
                  
                ) : (
                  <Login/>
                )
              }
            />
            <Route
              path="/"
              element={
                user ? (
                  user.role === "admin" ? (
                
                    <AuthGuard  Component={Admin } isThere={isThere} checkOAuthFile={checkOAuthFile} />
                  ) : 
                    <Home/>
                  
                ) : (
                  <Login/>
                )
              }
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />

            <Route path="/createuser" element={<CreateUser />} />

            <Route
              path="/organization/:organization_id"
              element={<OrganizationsDetails />}
            />
            <Route
              path="/createorganization"
              element={<CreateOrganization />}
            />

            <Route
              path="/aminOrgDetails/:organization_id"
              element={<OrganizationsDetails />}
            />
            <Route
              path="/organization/:organization_id/createsettings"
              element={<CreateSettingsForOrg />}
            />
            <Route
              path="/organization/:organization_id/settingslist"
              element={<SettingsList />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
