import React, { useState ,useEffect  } from "react";
import {
  Link,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';

// import { useAuthContext } from "../hooks/useAuthContext";
import { useAuthContext } from "../hooks/useAuthContext";
import useFetchUsers from "../hooks/useFetchUsers";


import Modal from "react-modal";;



const SettingForm = ({ organization_id , settingData }) => {
// const { users, isLoading, fetchError } = useFetchUsers(user.token);

const [isModalOpen, setIsModalOpen] = useState(false); // for pop model


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


const { user } = useAuthContext();

  useEffect(() => {
        
        if (!user) {
          return; // Exit early if user object is not available
        }
    const loadGoogleAPI = async () => {
      try {
        const response = await fetch(`/api/organization/webtoken?org_id=${organization_id}&parent_url=${window.location.href}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.token}`,
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch Google API URL');
        }
        const url = await response.json();
        // Load the Google API
        await new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.src = "https://apis.google.com/js/api.js";
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
        });
        // eslint-disable-next-line  no-undef
        gapi.load('gapi.iframes', function() {
          var options = {
            // the url from the ggett api ( web token ( organaization ))
            'url':'https://play.google.com/work/embedded/search?token=' + url + '&mode=SELECT',
            'where': document.getElementById('container'),
            'attributes': { style: 'width: max ; height:1000px', scrolling: 'yes'}
          }
          // eslint-disable-next-line no-undef
          var iframe = gapi.iframes.getContext().openChild(options);
          iframe.register('onproductselect', function(event) {
            console.log(event.packageName)
            setJsonText((prevJsonText) => {
              try {
                const jsonContent = JSON.parse(prevJsonText);
                const updatedJsonContent = {
                  ...jsonContent,
                  applications: [...jsonContent.applications,  { "installType": "FORCE_INSTALLED",  "packageName" : event.packageName} ],
                };
                return JSON.stringify(updatedJsonContent, null, 2);
              } catch (error) {
                console.error('Error updating JSON:', error);
                return prevJsonText;
              }
            });
          // eslint-disable-next-line no-undef
          }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);
        });
        
      } catch (error) {
        console.error('Error loading Google API:', error);
      }
    };

    loadGoogleAPI();
  }, [organization_id, user]);



  
  const defaultJson = `{
    "passwordRequirements": {
    "passwordMinimumLength": 6,
    "passwordQuality": "ALPHABETIC"
  },
  "applications": [
    
  ],
  "parentProfilePasswordRequirements": {
    "passwordMinimumLength": 4,
    "passwordQuality": "NUMERIC_COMPLEX"
  }}`;
  const navigate = useNavigate();
  const [name, setName] = useState(settingData ? settingData.name : "");
  const [organizationId, setOrganizationId] = useState(settingData ? settingData.org_id : organization_id);
  const [jsonFile, setJsonFile] = useState(null);
  const [jsonText, setJsonText] = useState( settingData ? JSON.stringify(settingData.file) : defaultJson); // State for JSON text input
  const [error, setError] = useState(null);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonContent = JSON.parse(e.target.result);
          setJsonFile(jsonContent);
        } catch (error) {
          console.error('Error parsing JSON file:', error);
        }
      };

      reader.readAsText(uploadedFile);
    }
  };

  const handleJsonInputChange = (event) => {
    setJsonText(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        name: name,
        organization_id: organizationId,
        JsonFile: jsonFile || JSON.parse(jsonText) // Use jsonFile if available, otherwise parse jsonText
      };
  
      const response = await fetch("/api/settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
          Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify(requestData) // Convert JavaScript object to JSON string
      });
  
      if (!response.ok) {
        throw new Error("Failed to create setting");
      }
  
      setError(null);
      navigate(-1)
    } catch (error) {
      setError(error.message);
    }
  };

  return (


    <form onSubmit={handleSubmit} style={{  margin: '0 auto' }}>
    <h1 style={{ textAlign: 'center' }} >Create New Setting</h1>
    {/* <h3 style={{ textAlign: 'center' }} >organization_id: {organization_id}</h3> */}
    <label style={{ display: 'block', margin: '0 auto', width: '80%', textAlign: 'center' }} >
      Name:
      <input
        type="text"
        value={name}S
        onChange={(e) => setName(e.target.value)}
        style={{ width: '100%' }}
      />
    </label>

  
    {error && <div className="error">{error}</div>}



    <div style={{ display: "flex",margin: '0 auto' ,  justifyContent: "space-between", alignItems: "center" , width : "50%"}}>
        <button type="button" onClick={openModal} style={{ marginTop: '20px', fontSize: '16px' }}>
          Check the JSON File
        </button>

        <button type="submit" style={{ marginTop: '20px', fontSize: '16px' }}>
      Submit Setting
    </button>


        <div style={{color: "green"}}>
          <label className="file-input-label" style={{ display: 'inline-block', fontSize: '14px', marginRight: '10px' }}>
            or upload file
            <input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              className="file-input"
              style={{ display: 'none' }}
            />
          </label>
          {jsonFile && (
            <div className="uploaded-json">
              <h2>Uploaded JSON Data:</h2>
              <pre>{JSON.stringify(jsonFile, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>

    {/* Modal */}
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Textarea Modal"
      style={{
        content: {
          maxWidth: '800px',
          margin: 'auto',
          padding: '20px',
        },
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h3>Enter JSON Manually</h3>
        <textarea
          style={{ padding: "10px", width: '90%' , height : '350px' }}
          rows="10"
          value={jsonText}
          onChange={handleJsonInputChange}
        ></textarea>
        <button onClick={closeModal} style={{ marginTop: '10px', fontSize: '14px' }}>
          Close
        </button>
      </div>
    </Modal>

    <div id="container" style={{height : 'auto' }}> </div>

   
  </form>
    
  );
};

export default SettingForm;



//https://storage.googleapis.com/android-management-api-samples/managed_play_iframe.html?mode=SELECT&token=WAKBtL_PU49jQYaGSepbAe8h4vJDLcr1AcqrrqxxiyGj5zYgbKD897DWfTnP8wF5op5XCVghIqeL4cXTeYlCBhKmiuYhn9oQOzL6SFDvsD8EZbISSZIFtgP3M6vpqVhCjUeyappeFzhoqTWYvxHnY5Uur1rW6jQP8FE_8_cPpGSeoyb53-dZZV8M8CFggMLzguIms75B6lLxdqK24Dq6j8jI-jmHvx3E34ANi577JnFYE62QdRuQziZ8
//