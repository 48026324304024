import {Link} from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
const NavBar = () => {
    const {logout} = useLogout()
    const {user } = useAuthContext()
    const onClickHandler = ()=>{
        logout()
    }
    let  isAdmin = false 
    if(user){
        if(user.role){
            if (user.role === 'admin'){
                isAdmin = true 
            }
            else {
                isAdmin=false 
            }
        }
    }
    
    return (
        <header>
            <div className="container">
                <Link to="/">
                    <h1>Android management API system</h1>
                </Link>
                <nav>
                { (user && isAdmin )? <Link className='link'  to="/createorganization">Create Organization</Link> : "" }
                    { (user && isAdmin )? <Link className='link' to="/createuser">Create user</Link> : "" }
                    {user && (
                        <div>
                        <button id='logout-btn' onClick={onClickHandler}>Log out </button>
                        <span className='username_holder'>{user.username}</span>
                    </div>

                    )}
                    
                    {!user && (
                        <div>
                        <Link className='link' to="/login">Login</Link>
                        {/* <Link to="/createuser">Create User</Link> */}
                    </div>
                    
                    )}

     



                    

                                  

                    
                </nav>
            </div>
        </header>
    )
}
export default NavBar




