import React, { useEffect, useState } from "react";
import { useSettingsContext } from "../../../hooks/useSettingsContext";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Link, useParams } from "react-router-dom";
import QRCodeCanvas from "qrcode.react";
import "./SettingsList.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
const SettingsList = ( { organization_id }) => {
  const [selectedSetting, setSelectedSetting] = useState(null);

  const openModal = (jsonFile) => {
    setSelectedSetting(jsonFile);
  };

  const closeModal = () => {
    setSelectedSetting(null);
  };
  const [editingRow, setEditingRow] = useState(null);
  const [editedSettings, setEditedSettings] = useState({});
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [selectedQRValue, setSelectedQRValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const { settings, dispatch } = useSettingsContext();
  const { user } = useAuthContext();
  // const { organization_id } = useParams();
  const [editingId, setEditingId] = useState(null);
  const [qrLoading, setQrLoading] = useState(true);

  const handleDeleteSetting = async (id) => {
    try {
      const response = await fetch(`/api/settings?id=${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to delete setting");
      }
      window. location. reload();
      dispatch({ type: "DELETE_SETTING", payload: id });
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const handleEditSetting = async (id, editedSetting) => {
    try {
      const { name, organization_id, file } = editedSetting;
      const response = await fetch(`/api/settings?id=${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          name,

      
          organization_id,
          file,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update setting");
      }
      window. location. reload();
      // Update the settings state or perform any necessary actions upon successful update
      setEditingRow(null); // Reset editing state after successful update
    } catch (error) {
      console.error("Error updating setting:", error);
      // Handle error
    }
  };

  const handleStartEditing = (id, name, organization_id, file) => {
    setEditingRow(id);
    setEditedSettings({ [id]: { name, organization_id, file } });
  };

  const handleInputChange = (id, field, value) => {
    setEditedSettings((prevSettings) => ({
      ...prevSettings,
      [id]: {
        ...prevSettings[id],
        [field]: value,
      },
    }));
  };

  const toggleQRPopup = async (value, id, org_id) => {
    // Making a POST request to refresh token
    try {
      setQrLoading(true);
      setShowQRPopup(!showQRPopup);
      const response = await fetch(`/api/settings/refreshToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          settingID: id,
          organization_id: org_id,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to refresh token");
        setQrLoading(false);
      }
      const qrcode = await response.json();

      setSelectedQRValue(qrcode);
     
      setQrLoading(false);
    } catch (error) {
      console.error("Error refreshing token:", error);
      setQrLoading(false);
      // Handle error
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!user || !user.token || !organization_id) return;

        const response = await fetch(
          `/api/settings/organization?org_id=${organization_id}`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch settings");
        }

        const json = await response.json();

        dispatch({ type: "SET_SETTING", payload: json });
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    };

    fetchSettings();
    
  }, [dispatch, organization_id, user]);


  


  return (
    <div className="settings-page">
      {/* <div className="page-nav">
        <Link className="link" to={`/organization/${organization_id}`}>
          Go Back to organization
        </Link>
      </div> */}
      <h1>Settings List</h1>
      {settings && settings.length > 0 ? (
        <div className="org-container">
          <table className="org-table">
            <thead>
               <tr>
                <th>Name</th>
                <th>Policy Name</th>
             
                <th>File</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting) => (
                <tr key={setting.id} className="setting-card">





                  <td>
                    {editingRow === setting.id ? (
                      <input
                        type="text"
                        value={editedSettings[setting.id]?.name || setting.name}
                        onChange={(e) =>
                          handleInputChange(setting.id, "name", e.target.value)
                        }
                      />
                    ) : (
                      setting.name
                    )}
                  </td>
            
                  <td>{setting.policy_name}</td>




                  <td>
  <button className="view-json-button" onClick={() => openModal(setting.file)}  style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} >
  <FontAwesomeIcon icon={faEye} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
  </button>
  
</td>



                  <td>

                  <div style={{ display: 'flex', gap: '8px' , alignItems: 'center' }}>
                    {editingRow === setting.id ? (
                      <>

                        <button
                          onClick={() =>
                            handleEditSetting(
                              setting.id,
                              editedSettings[setting.id]
                            )
                          }
                          style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} 
                        >
                          

                          <FontAwesomeIcon icon={faSave} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 


                        </button>
                        <button onClick={() => setEditingRow(null)} style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} >
                        <FontAwesomeIcon icon={faTimes} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                        </button>
                      </>
                    ) : (
                      <>
              <Link   style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none' ,color: 'black'}}
                        to= {`/organization/${setting.org_id}/createsettings`}
                        state={{ settingData:setting }}
                      >
                        <FontAwesomeIcon icon={faEdit} style={{ background: 'none', border: 'none', boxShadow: 'none' }} />
                      </Link>
                        <button onClick={() => handleDeleteSetting(setting.id)} style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} >
                        <FontAwesomeIcon icon={faTrashAlt} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                        </button>
                        <button
                          onClick={() =>
                            toggleQRPopup(
                              setting.enrollment_token,
                              setting.id,
                              setting.org_id
                            )
                          }
                          style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} 
                        >
                          <FontAwesomeIcon icon={faQrcode} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                        </button>
                      </>
                    )}
                  
                  </div>
                  </td>







                </tr>
              ))}
            </tbody>
          </table>
          <Modal
        isOpen={!!selectedSetting}
        onRequestClose={closeModal}
        contentLabel="JSON Modal"
      >
        <div style={{ textAlign: "center" }}>
          <h2>JSON File</h2>
          <pre>{JSON.stringify(selectedSetting, null, 2)}</pre>
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
        </div>
      ) : (
        <p>No settings found</p>
      )}

      {showQRPopup && (
        <div className="qr-popup" onClick={() => setShowQRPopup(false)}>
          <div className="qr-popup-content">
            {qrLoading ? (
              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            ) : (
              <>
                <QRCodeCanvas value={selectedQRValue} size={256} />
                <p className="text-qr">{selectedQRValue}</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsList;
