import { createContext, useReducer } from "react";


export const OrganizationsContext = createContext()
export const organizationsReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ORGANIZATION':
        return {
          organizations: action.payload,
        };
      case 'CREATE_ORGANIZATION':
        return {
          organizations: [action.payload, ...state.organizations],
        };
      case 'DELETE_ORGANIZATION':
        return {
          organizations: state.organizations.filter((org) => org._id !== action.payload._id),
        };

      case 'UPDATE_ORGANIZATION':
        return {
            organizations: state.organizations.map(organization => organization._id === action.payload._id ? action.payload : organization)
        }
        
      default:
        return state;
    }
  };
export const OrganizationsContextProvider = ({children}) => {
    const [state , dispatch ] = useReducer(organizationsReducer, {
        organizations : null
    })


    return (
        <OrganizationsContext.Provider value={{...state, dispatch}}>
            {children}
        </OrganizationsContext.Provider>
    )
}