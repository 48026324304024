import {useAuthContext } from './useAuthContext'
import {useSettingsContext } from './useSettingsContext'
export const useLogout = ()=>{
    const {dispatch} = useAuthContext()
    const {dispatch : settingsDispatch} = useSettingsContext()
    const logout = () => {
        //remove user from storage 
        localStorage.removeItem('user')
        //dispatch logout actiton 
        dispatch({type: 'LOGOUT'})
        settingsDispatch({type : 'SET_SETTING' , payload : null})
    }
    return {logout}
}