import { useState } from "react";
import { useCreateUser } from "../../../hooks/useCreateUser";
import './CreateUser.scss'
const CreateUser = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('basic'); // Default role: Basic
  const [showPassword, setShowPassword] = useState(false);
  const { createUser, error, isLoading, successMessage } = useCreateUser();
  const [passwordMismatchError, setPasswordMismatchError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMismatchError('Passwords do not match');
      return;
    }
    setPasswordMismatchError(''); // Clear the error if passwords match
    await createUser(username, password, role);
  };

  return (
    <form className="createuser" onSubmit={handleSubmit}>
      <h3>Create User</h3>
      
      <label>Username:</label>
      <input 
        type="text" 
        onChange={(e) => setUsername(e.target.value)} 
        value={username} 
      />
        <label>Password:</label>
      
      <div className="password-container">
        <input 
          type={showPassword ? 'text' : 'password'} 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
       
      </div>
        <label>Confirm Password:</label>
      <div className="password-container">
        <input 
          type={showPassword ? 'text' : 'password'} 
          onChange={(e) => setConfirmPassword(e.target.value)} 
          value={confirmPassword} 
        />
          <button className="password-viability" type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? 'Hide Password' : 'Show Password'}
        </button>
      </div>
    
 

      <label>User Role:</label>
      <div class="select-dropdown">
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="basic">basic</option>
        <option value="admin">admin</option>
      </select>
      </div>
 
      {isLoading ?  <div className="lds-ring"><div></div><div></div><div></div><div></div></div> : <button className="create-user-btn" disabled={isLoading}>Create</button>}
     
      {passwordMismatchError && <div className="error">{passwordMismatchError}</div>}
      {error && <div className="error">{error}</div>}
      {successMessage && <div className="success">{successMessage}</div>}
    </form>
  );
};

export default CreateUser;
