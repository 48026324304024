import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CreateAuth from './pages/admin/CreateAuth';

const AuthGuard = ({ Component, isThere, checkOAuthFile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkOAuthFile().then(() => setLoading(false));
  }, [checkOAuthFile]);

  if (loading) {
    return <div>Loading...</div>; // You might want to show a loader while checking OAuth file
  }

  if (isThere) {
    return <Component />;
  }

  // Redirect to another route if OAuth file is not present
  if (!isThere) {
    //return <Component />;
    return <CreateAuth/>;
  }
};

export default AuthGuard;