import { useState } from 'react'
import { useAuthContext } from './useAuthContext'

export const useCreateUser = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null);
  const { user } = useAuthContext();

  const createUser = async (username, password, role) => {
    setIsLoading(true)
    setError(null)

    const response = await fetch('/api/user/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ username, password, role }),
    });
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    } else {
      setIsLoading(false)
      // Set success message
      setSuccessMessage("User created successfully!");
      // Clear success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    }
  }

  return { createUser, isLoading, error, successMessage }
}
