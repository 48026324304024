import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from "../../hooks/useAuthContext";
import Admin from './Admin';
import Loading from '../../components/loading/Loading';
const CreateAuth = () => {
  const [isThere, setIsThere] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useAuthContext();
  const [formValues, setFormValues] = useState({
    type: '',
    project_id: '',
    private_key_id: '',
    private_key: '',
    client_email: '',
    client_id: '',
    auth_uri: '',
    token_uri: '',
    auth_provider_x509_cert_url: '',
    client_x509_cert_url: '',
    universe_domain: '',
    name: '',
    projectID: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleTextareaChange = (e) => {
    const { value } = e.target;
    try {
      const parsedValue = JSON.parse(value);
      setFormValues(parsedValue);
      setError(''); // Clear any previous error
    } catch (error) {
      console.error('Error parsing JSON:', error);
      setError('Invalid JSON format. Please input a valid JSON.');
    }
  };
// useEffect(() => {
//   console.log('g')
//   const checkOAuthFile = async () => {
//     try {
//       setLoading(true)
//       const response = await fetch('/api/serviceAccount/isthere');
//       console.log("*******************")
//       console.log("the respond status is ")
//       console.log(response.status)
//       console.log(response)
//       if (response.status === 200 || response.status === 304) {
//         setIsThere(true)
//         setLoading(false)
//       } else if (response.status === 500) {
//         setIsThere(false)
//         setLoading(false)
//       }
//     } catch (error) {
//       // Handle error if necessary
//       console.error('Error:', error);
//     }
//   };

//     checkOAuthFile();

// }, [])

// const response = await fetch("/api/organization/", {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${user.token}`,
//   },
//   body: JSON.stringify({
//     ...formData,
//     username: selectedUser.username,
//     user_id: selectedUser._id,
//   }),
// });

// const response = await fetch("/api/organization/", {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${user.token}`,
//   },
//   body: JSON.stringify({
//     ...formData,
//     username: selectedUser.username,
//     user_id: selectedUser._id,
//   }),
// });

  const handleSubmit = async (e) => {
    console.log("********************")
    console.log("the value of project id is ")
    console.log(formValues.projectID)
    e.preventDefault();
    try {
      setLoading(true)
      console.log("in create AUth")
      const response = await fetch('/api/serviceAccount', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
        data: formValues,
        name: formValues.name,
        projectID: formValues.projectID,
      }),
      });
      setSuccess(true);
      setLoading(false)
      // Redirect to /admin upon successful submission
      window.location.href = '/admin';
    } catch (error) {
      setError('Could not save service account.');
      setLoading(false)
      console.error('Error:', error);
    }
  };

  const stringifyFormValues = () => {
    return JSON.stringify(formValues, null, 2); // null and 2 are for formatting
  };
if (loading) return <Loading/>

  return (
    <div>

      {/* {checkOAuthFile()} */}
      <h3>No Service Account Auth found , Please create one.</h3>
      <div>
      {success && <p>Service account saved successfully.</p>}
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <textarea
          rows={15} 
          cols={50} 
          value={stringifyFormValues()}
          onChange={handleTextareaChange}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
    </div>
  );
};

export default CreateAuth;
