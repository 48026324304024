import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import SettingForm from '../../../components/SettingForm';
import './CreateSettings.scss';

const CreateSettingsForOrg = (props) => {
  const { organization_id } = useParams();
  const location = useLocation()
  const { state } = location;
  const settingData = state ? state.settingData : null;
  console.log(settingData)
  return (
    <div className='create-setting-page'>
      <div className='page-nav'>
        <Link className='link' to={`/organization/${organization_id}`}>
          Go Back to organization
        </Link>
      </div>
      <div className='settings-form'>
        {/* Pass organization_id and settingData to SettingForm */}
        <SettingForm organization_id={organization_id} settingData={settingData} />
      </div>
    </div>
  );
};

export default CreateSettingsForOrg;
