import React, { useState, useEffect, useContext } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";
import useFetchUsers from "../../hooks/useFetchUsers";
import OrganizationForm from "../../components/OrganizationForm";
import { OrganizationsContext } from "../../context/organizationsContext";
import { format } from "date-fns";
import Loading from "../../components/loading/Loading"
import CreateAuth from "./CreateAuth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// import GoogleClientConfigForm from "../../components/googleClientConfigForm";

const Admin = () => {

  const [editOrganizationNames, setEditOrganizationNames] = useState({});
  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState({});
  const [editUsername, setEditUsername] = useState({});
  const { user } = useAuthContext();
  const { users, error: usersError } = useFetchUsers(user.token);

  const { organizations, dispatch } = useContext(OrganizationsContext);






  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await fetch("/api/organization/allorgs", {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch organizations");
        }
        const data = await response.json();
        // console.log("data is")
        // console.log(data)
        dispatch({ type: "SET_ORGANIZATION", payload: data });
        setEditOrganizationNames(
          data.reduce((acc, org) => {
            acc[org.id] = org.name;
            return acc;
          }, {})
        );
      } catch (error) {
        console.error(error);
        throw new Error("Error in fetching the data");
      }
    };
    fetchOrganizations();
  }, [user, dispatch]);

  const handleDeleteOrganization = async (id) => {
    console.log(`/api/organization?id=${id}`)
    try {
      const response = await fetch(`/api/organization?id=${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to delete organization");
      }
      window. location. reload();
      const data = await response.json();
      dispatch({ type: "DELETE_ORGANIZATION", payload: data });
      // Filter out the deleted organization from the state
    } catch (error) {
      // Handle error if necessary
      console.error(error);
    }
  };

  const handleEditClick = (orgId) => {
    setEditOrganizationNames({
      ...editOrganizationNames,
      [orgId]: organizations.find((org) => org.id === orgId).name,
    });
    setEditMode({ ...editMode, [orgId]: true });
  };

  // here need to fix
  const handleEditOrganization = async (orgId) => {
    try {
      let selectedUserId = null;
      let updatedName = editOrganizationNames[orgId];
      let updatedUsername = null;

      // If a username is selected, find the corresponding user ID
      if (editUsername[orgId]) {
        const selectedUser = users.find(
          (user) => user.username === editUsername[orgId]
        );

        if (!selectedUser) {
          throw new Error("Selected user not found");
        }

        selectedUserId = selectedUser.id;
        updatedUsername = selectedUser.username;
      } else {
        // If no user is selected, maintain the existing user_id and username for the organization
        const org = organizations.find((org) => org.id === orgId);
        selectedUserId = org.user_id;
        updatedUsername = org.username;
      }

      const response = await fetch(`/api/organization`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          name: updatedName,
          user_id: selectedUserId,
          org_id: orgId, // Updated username
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update organization");
      }
      console.log("********************************")
      window. location. reload();
      const updatedOrg = await response.json();

      // Dispatch the updated organization to the context
      dispatch({ type: "UPDATE_ORGANIZATION", payload: updatedOrg });
      
      // Refresh the local state, if necessary
      const updatedOrgs = organizations.map((org) =>
        org.id === updatedOrg.id ? updatedOrg : org
      );

      // setOrganizations(updatedOrgs);
      setEditOrganizationNames(updatedOrgs);
      setError("");
      setEditMode({ ...editMode, [orgId]: false });
    } catch (error) {
      setError(error.message);
      // Handle error if necessary
    }
  };

if (!organizations) return <Loading/>
  return (
    <div>
      <h1>Admin Page</h1>
      {/* <GoogleClientConfigForm/> */}
      {/* commented on 14 12 2023, create organization has its own page now */}
      {/* <h2>Create New Organization</h2>
      <OrganizationForm /> */}
      {/* Display error message... */}
      <div className="org-container">
        <table className="org-table">
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Username</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.map((org) => (
                <tr key={org.id}>
                  <td>
                    {editMode[org.id] ? (
                      <input
                        type="text"
                        value={editOrganizationNames[org.id]}
                        onChange={(e) =>
                          setEditOrganizationNames({
                            ...editOrganizationNames,
                            [org.id]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <div>
                        <Link
                          className="org-name"
                          to={`/organization/${org.id}`}
                        >
                          {org.name}
                        </Link>
                      </div>
                    )}
                  </td>
                  <td>
                    {editMode[org.id] ? (
                      <select
                        value={editUsername[org.id] || org.user}
                        onChange={(e) =>
                          setEditUsername({
                            ...editUsername,
                            [org.id]: e.target.value,
                          })
                        }
                      >
                        {users.map((user) => (
                          <option key={user.id} value={user.username}>
                            
                            {user.username}
                          </option>
                        ))}
                      </select>
                    ) : (
                      org.username
                    )}
                  </td>
                  {/* change thease back to created_at and updated_at */}
                  <td>
                    {format(
                      new Date(org.createdAt),
                      "MM d, yyyy"
                    )}
                  </td>
                  <td>
                    {format(
                      new Date(org.updatedAt),
                      "MM d, yyyy"
                    )}
                  </td>
                  <td>
                    {editMode[org.id] ? (
                      <>
                        <button onClick={() => handleEditOrganization(org.id)} style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}}>
                        <FontAwesomeIcon icon={faSave} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                        </button>
                        <button
                          onClick={() =>
                            setEditMode({ ...editMode, [org.id]: false })
                          }
                          style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} 
                        >
                          <FontAwesomeIcon icon={faTimes} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                        </button>
                        {error && <p>{error}</p>}
                      </>
                    ) : (
                      <button onClick={() => handleEditClick(org.id)} style={{ background: 'none' ,  border: 'none' , outline: 'none' ,boxShadow :'none'}} >
                        <FontAwesomeIcon icon={faEdit} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                      </button>
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleDeleteOrganization(org.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ background: 'none', border: 'none', boxShadow: 'none' }} /> 
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* <OrganizationForm /> */}
      </div>
    </div>
  );
};

export default Admin;
