import React from 'react';

const EnterpriseList = ({ enterprises, onDelete, onNavigate }) => {
  return (
    <div>
      <h2>Enterprises List</h2>
      <ul>
        {enterprises.map((enterprise) => (
          <li key={enterprise.name}>
            {enterprise.enterpriseDisplayName}{' '}
            <button onClick={() => onDelete(enterprise.name)}>Delete</button>{' '}
            <button onClick={() => onNavigate(enterprise.name)}>Details</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EnterpriseList;
