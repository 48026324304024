import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import useFetchUsers from "../../../hooks/useFetchUsers";
import './CreateOrganization.scss'
const CreateOrganization = () => {
  const [formData, setFormData] = useState({
    contactEmail: "",
    dataProtectionOfficerName: "",
    dataProtectionOfficerEmail: "",
    dataProtectionOfficerPhone: "",
    euRepresentativeName: "",
    euRepresentativeEmail: "",
    euRepresentativePhone: "",
    enterpriseDisplayName: "",
    name: "", // Add organization name
  });
  const [selectedUser, setSelectedUser] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const [loadingUser, setLoadingUser] = useState(true);
  const { users, isLoading, fetchError } = useFetchUsers(
    user ? user.token : ""
  );

  useEffect(() => {
    if (user) {
      setLoadingUser(false);
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/organization/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...formData,
          username: selectedUser.username,
          user_id: selectedUser._id,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create organization");
      }

      setFormData({
        contactEmail: "",
        dataProtectionOfficerName: "",
        dataProtectionOfficerEmail: "",
        dataProtectionOfficerPhone: "",
        euRepresentativeName: "",
        euRepresentativeEmail: "",
        euRepresentativePhone: "",
        enterpriseDisplayName: "",
        name: "", // Reset organization name
      });
      setSelectedUser("");
      setError("");
    } catch (error) {
      setError("Error creating organization");
    }
  };
  if (loadingUser) return <h1>Loading</h1>
  return (
    <div className="create-org">
      <h3>Create Organization</h3>
      {isLoading ? (
       <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Organization Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Contact Email:</label>
            <input
              type="text"
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Data Protection Officer Name:</label>
            <input
              type="text"
              name="dataProtectionOfficerName"
              value={formData.dataProtectionOfficerName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Data Protection Officer Email:</label>
            <input
              type="email"
              name="dataProtectionOfficerEmail"
              value={formData.dataProtectionOfficerEmail}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Data Protection Officer Phone:</label>
            <input
              type="tel"
              name="dataProtectionOfficerPhone"
              value={formData.dataProtectionOfficerPhone}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>EU Representative Name:</label>
            <input
              type="text"
              name="euRepresentativeName"
              value={formData.euRepresentativeName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>EU Representative Email:</label>
            <input
              type="email"
              name="euRepresentativeEmail"
              value={formData.euRepresentativeEmail}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>EU Representative Phone:</label>
            <input
              type="tel"
              name="euRepresentativePhone"
              value={formData.euRepresentativePhone}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Enterprise Display Name:</label>
            <input
              type="text"
              name="enterpriseDisplayName"
              value={formData.enterpriseDisplayName}
              onChange={handleInputChange}
              required
            />
          </div>

         
            <div class="select-dropdown">
            <select
              onChange={(e) => setSelectedUser(JSON.parse(e.target.value))}
            >
              <option value="">Select User</option>
              {users.map((user) => (
                <option key={user._id} value={JSON.stringify(user)}>
                  {user.username}
                </option>
              ))}
            </select>
            </div>
     

          <button className="create-org-btn" type="submit">Create Organization</button>
          {error && <div className="error">{error}</div>}
          {fetchError && <div className="error">Error fetching users</div>}
        </form>
      )}
    </div>
  );
};

export default CreateOrganization;
