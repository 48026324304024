import React from 'react';

import { useState , useEffect } from 'react';


const EnterpriseDetails = ({ enterprise }) => {


  const [enterpriseData , setEnterprise] = useState()

  useEffect(()=> {fetch('/api/enterprise/get', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"enterpriseId" : enterprise}),
  })
    .then((response) => response.json())
    .then((data) => {
      setEnterprise(data)
    })
    .catch((error) => console.error('Error creating enterprise:', error));
} , [])

  console.log(enterpriseData)
  console.log("**********************************")

  return (
    <div>
      <h2>Enterprise Details</h2>
      <p>Name: {enterpriseData?.name}</p>
      <p>Display Name: {enterpriseData?.enterpriseDisplayName}</p>

      <h3>Contact Information</h3>
      <p>Contact Email: {enterpriseData?.contactInfo?.contactEmail}</p>
      <p>Data Protection Officer Name: {enterpriseData?.contactInfo?.dataProtectionOfficerName}</p>
      <p>Data Protection Officer Email: {enterpriseData?.contactInfo?.dataProtectionOfficerEmail}</p>
      <p>Data Protection Officer Phone: {enterpriseData?.contactInfo?.dataProtectionOfficerPhone}</p>
      <p>EU Representative Name: {enterpriseData?.contactInfo?.euRepresentativeName}</p>
      <p>EU Representative Email: {enterpriseData?.contactInfo?.euRepresentativeEmail}</p>
      <p>EU Representative Phone: {enterpriseData?.contactInfo?.euRepresentativePhone}</p>

    </div>
  );
};

export default EnterpriseDetails;
