import React from "react";
import { Link, useParams } from "react-router-dom";
import SettingsList from '../SettingsList/SettingsList'
export const OrganizationsDetails = () => {
  const { organization_id } = useParams();

  return (
    <div>
      <div className="page-nav">
      <Link className="link" to={`/organization/${organization_id}/createsettings`}>CreateSettingsForOrg</Link>
      {/* <Link className="link" to={`/organization/${organization_id}/settingslist`}>ListSettingsForOrg</Link> */}
    
      </div>
      <SettingsList organization_id={organization_id} />
    </div>
  );
};
